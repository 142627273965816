import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Clubs/ScienceClub/2023/senior/1.jpg';
import p2 from 'assests/Photos/Clubs/ScienceClub/2023/senior/2.jpg';
import p3 from 'assests/Photos/Clubs/ScienceClub/2023/senior/3.jpg';
import p4 from 'assests/Photos/Clubs/ScienceClub/2023/senior/4.jpg';
import SideBarSci from 'views/ScienceClub/SideBarSci';
import Scienceclubsidebar2023 from 'views/ScienceClub/Scienceclubsidebar2023';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import ScienceClubSideBar from 'views/ScienceClubSideBar/ScienceClubSideBar';


const SeniorAssociation2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 2,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 2,
        },
    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                SCIENCE ART
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes: 9 - 12  &  Date: 25 JULY 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The Science Association organised an activity on ‘Science Art’ for the students of classes 9 to 12 on Tuesday, 25th July 2023. Students depicted the concepts of Science through Art. The participants presented the concepts of interesting topics such as ‘The development of Periodic table’, ‘Genetic Engineering’ and ‘Electronic Engines’ in a creative and insightful way.
                                    <br></br>
                                    The objective of the activity was to celebrate the integration of Science and Art. The success of the event was evident in the way the students came up with original and creative presentations. It was an overall enriching and innovative learning experience for the students.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "THERE IS AN ART IN SCIENCE AND SCIENCE IN ART"

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 400 : 300}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Scienceclubsidebar2023 />
                        </Box>
                        <Box marginBottom={4}>
                            <ScienceClubSideBar />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default SeniorAssociation2023;